const colorByType = {
  melk: "#c5413a",
  noir: "#cb2f50",
  puur: "#51453b",
  nootjes: "#6fc151",
  karamel: "#51453b",
}

export const mkPrintImage =
  /**
   * @param {{ readonly type: 'karamel' | 'melk' | 'noir' | 'nootjes' | 'puur'; readonly name: string; readonly lang: 'nl' | 'fr' }} opts
   * @return {Promise<Blob>}
   */
  async (opts) => 
{
  const { type, name, lang } = opts;

  const bgImage = new Image();
  
  const bgImageLoadHandle = new Promise((resolve) => {
    bgImage.onload = () => {
      resolve();
    }
  });

  bgImage.src = `/img/print-files/${type}.png`;

  await bgImageLoadHandle;

  /** @type {HTMLCanvasElement} */
  const canvas = document.createElement('canvas');

  canvas.width = 1925;
  canvas.height = 767;

  /** @type {CanvasRenderingContext2D} */
  const ctx = canvas.getContext('2d');

  ctx.drawImage(bgImage, 0, 0, 1925, 767);
  ctx.fillStyle = colorByType[type];
  ctx.textAlign = 'center';

  const text = lang === 'fr' ? `${name} en Or` : `${name} van Goud`;
  
  let fontSize = 160;
  ctx.font = `${fontSize}px Amarillo`;

  while (ctx.measureText(text).width > canvas.width * 0.9) {
    fontSize--;
    ctx.font = `${fontSize}px Amarillo`;
  }

  ctx.fillText(
    text,
    canvas.width / 2,
    (canvas.height / 2) * 1.2,
  );

  /** @type {Blob} */
  const image = await new Promise((resolve) => {
    canvas.toBlob((result) => {
      resolve(result);
    });
  });

  return image;
}
