import './components/select';
import textFit from 'textfit';
import { mkPrintImage } from './components/mk-print-image';
// Main js file

const inputName = document.getElementById('name'),
      submit    = document.querySelector('.form__submit'),
      packItem  = document.querySelectorAll('.pack__item'),
      select    = document.getElementById('select'),
      selectSelected = document.querySelector('.select-selected');

if (document.body.classList.contains('form-page')) {
    const checkLength = function () {
        if (inputName.value.length >= 1) {
            submit.removeAttribute('disabled');
        }
    };
    
    setInterval(() => {
        if(selectSelected.innerHTML === "Andere..." || selectSelected.innerHTML === "Autres..."){
            document.body.classList.remove('input-hidden');
            inputName.addEventListener('keyup', checkLength);
            submit.addEventListener('click', () => {
                localStorage.setItem("name", inputName.value);
            });
        } else if (selectSelected.innerHTML === "Selecteer..." || selectSelected.innerHTML === "Sélectionner...") {
            document.body.classList.add('input-hidden');
        } else {
            document.body.classList.add('input-hidden');
            submit.removeAttribute('disabled');
            submit.addEventListener('click', () => {
                localStorage.setItem("name", select.value);
            });
        }
    }, 300);
}

if (document.body.classList.contains('cookie-page')) {
    packItem.forEach(el => {
        const nameOnPack = el.querySelector('.name'),
              packTitle = el.querySelector('.pack__item-title'),
              article   = el.querySelector('.pack__item-title .article');
        
        nameOnPack.innerHTML = localStorage.getItem('name');

        textFit(packTitle, {minFontSize:14, maxFontSize: 30, reProcess : false, widthOnly: true});
    });
}

if (document.body.classList.contains('print-page')) {
    document.addEventListener("DOMContentLoaded", async() => {
        const btnPrint     = document.querySelector('.print__btn'),
              documentName = btnPrint.getAttribute('data-name'),
              lang         = btnPrint.getAttribute('data-lang'),
              name         = localStorage.name,
              printImage   = document.querySelector('.print__pack-image');
        if (!documentName || !lang || !name) {
            return;
        }

        const previewImageBlob = await mkPrintImage({ type: documentName, lang: lang, name: name });
        const previewImageURL = URL.createObjectURL(previewImageBlob);
    
        const previewImage = new Image();
        const previewImageLoadHandle = new Promise((resolve) => {
            previewImage.onload = () => {
                URL.revokeObjectURL(previewImageURL);
            };
        });
    
        previewImage.src = previewImageURL;

        printImage.style.backgroundImage = "url(" + previewImage.src + ")";

        await previewImageLoadHandle;
    
    });

    // document.querySelector('.name').innerHTML = localStorage.getItem('name');

    // const printPack = document.querySelector('.print__pack-title'),
    //       article   = document.querySelector('.print__pack-title .article');

    // textFit(printPack, {minFontSize:35, maxFontSize: 95, reProcess : false, widthOnly: true});
}

document.addEventListener('click', async (event) => {
  if (!event.target) {
    return;
  }

  const element = event.target.classList.contains('print__btn') ? event.target : event.target.closest('.print__btn');

  if (!element) {
    return;
  }

  /** @type {'karamel' | 'melk' | 'noir' | 'nootjes' | 'puur'} */
  const documentName = element.getAttribute('data-name');
  /** @type {'nl'|'fr'} */
  const lang = element.getAttribute('data-lang');
  /** @type {string} */
  const name = localStorage.name;
  if (!documentName || !lang || !name) {
    return;
  }

  const image = await mkPrintImage({ type: documentName, lang: lang.toLowerCase(), name });
  const formData = new FormData();

  formData.append('image', image);

  try {
    const response = await fetch(
      `/${lang}/print`,
      {
        method: 'POST',
        body: formData,
      }
    );
    
    if (!response.ok) {
      alert('Something went wrong, please contact technical support.');
    } else {
      location.href = '/';
    }

  } catch {
    alert('Something went wrong, please contact technical support.');
  }
});
